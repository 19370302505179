import {EventEmitter, Injectable, Output} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {environment} from "../../environments/environment";
import {
  ChangePasswordDto,
  ForgotPasswordDto,
  LoginDto,
  LoginResultDto,
  ResetPasswordDto,
  UserDto
} from "../core/app.interfaces";

@Injectable()
export class AuthService  {
  @Output() currentUser: EventEmitter<{user: UserDto, locale: string}> = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  login(body: LoginDto): Observable<LoginResultDto> {
    return this.http.post(`${environment.serverUrl}/auth/login`, body) as Observable<LoginResultDto>;
  }

  changePassword(body: ChangePasswordDto): Observable<LoginResultDto> {
    return this.http.post(`${environment.serverUrl}/auth/password`, body) as Observable<LoginResultDto>;
  }

  forgotPassword(body: ForgotPasswordDto): Observable<ForgotPasswordDto> {
    return this.http.post(`${environment.serverUrl}/auth/forgot`, body) as Observable<ForgotPasswordDto>;
  }

  getResetPassword(token: ResetPasswordDto): Observable<Object> {
    return this.http.get(`${environment.serverUrl}/auth/reset/${token.token}`) as Observable<UserDto>;
  }

  postResetPassword(token: ResetPasswordDto, body: ChangePasswordDto): Observable<LoginResultDto> {
    return this.http.post(`${environment.serverUrl}/auth/reset/${token.token}`, body) as Observable<LoginResultDto>;
  }

  logout(): Observable<Object> {
    return this.http.post(`${environment.serverUrl}/auth/logout`, null) as Observable<Object>;
  }

}
