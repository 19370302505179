<main class="middle">
  <div class="account-container">
    <div class="content">
      <h3>{{'password.title' | translate}}</h3>
      <div class="row">
        <div class="change-pass-form">
          <form [formGroup]="form" autocomplete="off">

            <div class="form-group">
              <label for="new-pass">{{'password.new-password.label' | translate}}:</label>
              <div class="form-control">
                <input id="new-pass" name="password" [(ngModel)]="entity.password"  formControlName="password"
                       maxlength="100" placeholder="{{'password.new-password.placeholder' | translate}}" type="password" autocomplete="off" required>
                <div *ngIf="form.get('password').hasError('required') && $submitted">
                  <p class="validation-error">
                    {{'password.new-password.required-error' | translate}}
                  </p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="confirm-pass">{{'password.confirm-password.label' | translate}}:</label>
              <div class="form-control">
                <input id="confirm-pass" name="confirmPassword" [(ngModel)]="entity.confirmPassword" formControlName="confirmPassword"
                       maxlength="100" placeholder="{{'password.confirm-password.placeholder' | translate}}" type="password" autocomplete="off" required >
                <div *ngIf="$submitted">
                  <p [hidden]="!form.get('confirmPassword').hasError('required')" class="validation-error">
                    {{'password.confirm-password.required-error' | translate}}
                  </p>
                  <p [hidden]="!form.get('confirmPassword').hasError('notEquals')" class="validation-error">
                    {{'password.confirm-password.match-error' | translate}}
                  </p>
                </div>
              </div>
            </div>

            <div class="btn-group">
              <button class="btn btn-submit" type="submit" (click)="save()"
                      [disabled]="requestProcessing">
                <i class="fa fa-spinner fa-spin" [hidden]="!requestProcessing"></i>
                {{'password.submit-button' | translate}}
              </button>
              <button class="btn btn-cancel" (click)="cancel()">
                {{'password.cancel-button' | translate}}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</main>
