import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
var PurchasesService = /** @class */ (function () {
    function PurchasesService(http) {
        this.http = http;
    }
    PurchasesService.prototype.getPurchases = function () {
        return this.http.get(environment.serverUrl + "/purchases");
    };
    return PurchasesService;
}());
export { PurchasesService };
