export let AppEnums = {

  fileType: {
    certificate: 'certificate',
    folder: 'folder'
  },

  storageKeys: {
    'XAuthToken': 'X-AUTH-TOKEN',
    'User': 'User',
    'LastCreatedId': 'LastCreatedId',
    'RememberMe': 'RememberMe'
  },

  storageTypes: {
    sessionStorage: 'sessionStorage',
    localStorage: 'localStorage'
  }

};
