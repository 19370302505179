import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {UserProfileDto} from "../core/app.interfaces";
import {environment} from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class ProfileService {

  constructor(private http: HttpClient) { }

  getUserProfile(): Observable<UserProfileDto> {
    return this.http.get(`${environment.serverUrl}/profile/`) as Observable<UserProfileDto>;
  }

  updateUserProfile(userProfile: UserProfileDto): Observable<UserProfileDto> {
    return this.http.put(`${environment.serverUrl}/profile/`, userProfile, httpOptions) as Observable<UserProfileDto>;
  }
}
