import { Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthStateService } from "../core/auth-state.service";
import { ToastrService } from "ngx-toastr";
import { CurrentUserService } from "../core/current-user.service";
import { AuthService } from "../auth/auth.service";
import { StorageService } from "../core/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { ProfileService } from "./profile.service";
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(authService, currentUserService, storageService, activatedRoute, toastr, authStateService, router, spinnerService, translate, profileService) {
        var _this = this;
        this.authService = authService;
        this.currentUserService = currentUserService;
        this.storageService = storageService;
        this.activatedRoute = activatedRoute;
        this.toastr = toastr;
        this.authStateService = authStateService;
        this.router = router;
        this.spinnerService = spinnerService;
        this.translate = translate;
        this.profileService = profileService;
        this.currentUser = {
            name: '',
            email: '',
            language: this.translate.currentLang,
            organization: ''
        };
        this.entity = {};
        this.translate.onLangChange.subscribe(function (event) {
            _this.selectedLanguage = event.lang;
        });
        var injector = Injector.create([{ provide: FormBuilder, deps: [] }]);
        this.formBuilder = injector.get(FormBuilder);
        this.form = this.initializeForm();
        this.formProfile = this.formBuilder.group({
            'language': [''],
            'name': [''],
            'email': ['']
        });
        this.spinnerService.hide();
    }
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileService.getUserProfile().subscribe(function (user) {
            _this.currentUser = user;
            _this.selectedLanguage = user.language;
            _this.formProfile.patchValue({ 'name': _this.currentUser.name });
            _this.formProfile.patchValue({ 'email': _this.currentUser.email });
        }, function (error) {
            var errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            console.log(errorMsg);
        });
    };
    ProfileComponent.prototype.changeLanguage = function ($event) {
        this.selectedLanguage = $event['value'];
    };
    ProfileComponent.prototype.save = function () {
        var _this = this;
        this.$submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.requestProcessing = true;
        this.authService.changePassword({
            password: this.entity.password,
            confirmPassword: this.entity.confirmPassword
        }).subscribe(function (result) {
            _this.toastr.info('Password was changed successfully!');
            _this.requestProcessing = false;
        }, function (error) {
            var msg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.toastr.error(msg);
            _this.requestProcessing = false;
        });
    };
    ProfileComponent.prototype.submitProfile = function (form) {
        var _this = this;
        this.translate.use(this.selectedLanguage);
        var profile = { name: form.name, email: form.email, language: form.language };
        this.profileService.updateUserProfile(profile).subscribe(function (result) {
            localStorage.setItem('locale', result.language);
            var user = _this.currentUserService.get();
            user.name = result.name;
            _this.currentUserService.set({ user: user });
        });
    };
    ProfileComponent.prototype.initializeForm = function () {
        var newPassword = this.formBuilder.control("", [Validators.required]);
        var confirmPassword = this.formBuilder.control("", [Validators.required, this.equaledPasswordsValidator.bind(this)]);
        /**
         * Update Validation when new password has changes
         */
        newPassword.valueChanges.subscribe(function () {
            confirmPassword.updateValueAndValidity();
        });
        return this.formBuilder.group({ password: newPassword, confirmPassword: confirmPassword });
    };
    ProfileComponent.prototype.equaledPasswordsValidator = function (control) {
        if (!control.parent)
            return null;
        var firstCtrl = control.parent.controls['password'];
        if (!control.value || !firstCtrl || !firstCtrl.value)
            return null;
        var isValid = firstCtrl.value === control.value;
        return isValid ? null : { notEquals: "Passwords aren't equal" };
    };
    return ProfileComponent;
}());
export { ProfileComponent };
