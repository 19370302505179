import { EventEmitter } from "@angular/core";
import { StorageService } from "./storage.service";
import { AppEnums } from "./app-constants";
var AuthStateService = /** @class */ (function () {
    function AuthStateService(storageService) {
        this.storageService = storageService;
        this.onTokenClear = new EventEmitter();
        this.token = this.storageService.get(AppEnums.storageKeys.XAuthToken);
    }
    AuthStateService.prototype.setToken = function (token) {
        this.token = token;
        if (token !== null) {
            this.storageService.set(AppEnums.storageKeys.XAuthToken, token);
        }
        else {
            this.storageService.remove(AppEnums.storageKeys.XAuthToken);
        }
    };
    AuthStateService.prototype.getToken = function () {
        return this.token;
    };
    AuthStateService.prototype.clearToken = function () {
        if (this.token === null)
            return;
        this.token = null;
        this.storageService.remove(AppEnums.storageKeys.XAuthToken);
        this.onTokenClear.emit(null);
    };
    return AuthStateService;
}());
export { AuthStateService };
