import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from "../../core/current-user.service";
import { StorageService } from "../../core/storage.service";
import { AuthService } from "../auth.service";
import { NgForm } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ProfileService } from "../../profile/profile.service";
var LoginPageComponent = /** @class */ (function () {
    function LoginPageComponent(authService, currentUserService, storageService, activatedRoute, toastr, router, spinnerService, profileService) {
        this.authService = authService;
        this.currentUserService = currentUserService;
        this.storageService = storageService;
        this.activatedRoute = activatedRoute;
        this.toastr = toastr;
        this.router = router;
        this.spinnerService = spinnerService;
        this.profileService = profileService;
        this.entity = {};
        this.spinnerService.hide();
    }
    LoginPageComponent.prototype.ngOnInit = function () {
        this.spinnerService.hide();
    };
    LoginPageComponent.prototype.login = function () {
        var _this = this;
        this.$submitted = true;
        if (!this.editForm.valid) {
            return;
        }
        this.requestProcessing = true;
        this.authService.login({
            email: this.entity.email,
            password: this.entity.password
        }).subscribe(function (result) {
            var returnUrl = _this.activatedRoute.snapshot.queryParams['returnUrl'];
            if (result.pswneedchange) {
                _this.currentUserService.redirectToChangePassword(result.token, returnUrl);
                return;
            }
            _this.currentUserService.set(result);
            _this.profileService.getUserProfile().subscribe(function (value) {
                _this.authService.currentUser.emit({ user: result.user, locale: value.language });
                _this.requestProcessing = false;
            }, function (error) {
                console.log(error); // TODO: handle error;
            });
            if (returnUrl) {
                _this.router.navigateByUrl(returnUrl);
            }
            else {
                _this.currentUserService.redirectToDefaultPage();
            }
        }, function (error) {
            var msg = error.status === 401 ? error.error['message'] : "Something wrong.";
            _this.toastr.error(msg);
            _this.requestProcessing = false;
        }, function () {
            _this.requestProcessing = false;
        });
    };
    LoginPageComponent.prototype.cancel = function () {
    };
    return LoginPageComponent;
}());
export { LoginPageComponent };
