export class SingleRequestService {

  private _pendingPromises: Array<any>;
  protected entity: any;
  private _loading: boolean;

  constructor() {
    this.entity = null;
    this._loading = false;
    this._pendingPromises = [];
  }

  protected getList(): Promise<any> {
    return Promise.reject("no resource");
  }

  public load() {

    const pending = this.buildDeffered();

    if (this.entity == null) {

      this._pendingPromises.push(pending);

      if (this._loading === false) {

        this._loading = true;

        this.getList()
          .then((successResult: any) => {
            this._loading = false;
            this._setEntity(successResult);
            this._resolve(this.entity);
          }, (error: any) => {
            this._loading = false;
            for (let i = 0; i < this._pendingPromises.length; ++i) {
              this._pendingPromises[i].reject(error);
            }
            this._pendingPromises = [];
          });
      }

    } else if (this._loading === false) {
      pending.resolve(this.entity);
    }

    return pending.promise;
  }

  public set(entity: any) {
    this.entity = entity;
  }

  public get() {
    return this.entity;
  }

  private _resolve(entity: any) {
    for (let i = 0; i < this._pendingPromises.length; ++i) {
      this._pendingPromises[i].resolve(entity);
    }

    this._pendingPromises = [];
  }

  protected _setEntity(result: any) {
    this.entity = result.data;
  }

  private buildDeffered() {
    let deffer: any = {};

    deffer.promise = new Promise((resolve, reject) => {
      deffer.resolve = resolve;
      deffer.reject = reject;
    });

    return deffer;
  }
}
