import { HttpResponse } from "@angular/common/http";
import { AuthStateService } from "./auth-state.service";
import { AppEnums } from "./app-constants";
import { Router } from "@angular/router";
import { throwError } from 'rxjs';
// import 'rxjs/add/operator/filter';
// import 'rxjs/add/operator/catch';
// import 'rxjs/observable/throw';
//import 'rxjs/Rx';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(_authStateService, _router) {
        this._authStateService = _authStateService;
        this._router = _router;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        console.info("HTTP | ", req.urlWithParams);
        var token = this._authStateService.getToken();
        var handleObs = token
            ? next.handle(req.clone({ headers: req.headers.set(AppEnums.storageKeys.XAuthToken, token) }))
                .filter(function (event) { return event instanceof HttpResponse; })
                .do(function (response) { return _this._refreshToken(response); })
            : next.handle(req)
                .filter(function (event) { return event instanceof HttpResponse; });
        return handleObs.catch(function (error) { return _this._errorHandler(error); });
    };
    AuthInterceptor.prototype._refreshToken = function (response) {
        //const token = response.headers.get("x-auth-token");
        //if(token) this._authStateService.setToken(token);
    };
    AuthInterceptor.prototype._errorHandler = function (error) {
        console.info("HTTP Error | ", error);
        if (error.status == 401) {
            this._authStateService.clearToken();
            this._router.navigate(['login']);
        }
        return throwError(error);
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
