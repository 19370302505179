/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./folder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "ng2-dnd";
import * as i5 from "./folder.component";
import * as i6 from "@angular/router";
var styles_FolderComponent = [i0.styles];
var RenderType_FolderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FolderComponent, data: {} });
export { RenderType_FolderComponent as RenderType_FolderComponent };
function View_FolderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "item-btn-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "button", [["class", "btn btn-edit"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editName($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "button", [["class", "btn btn-del"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteFolder($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_FolderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "item-file-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.folder.name; _ck(_v, 1, 0, currVal_0); }); }
function View_FolderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "item-file-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["nameInput", 1]], null, 5, "input", [["id", "folder-name"], ["name", "folder-name"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"], [null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.folder.name = $event) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = ($event.stopPropagation() !== false);
        ad = (pd_5 && ad);
    } if (("keyup.enter" === en)) {
        var pd_6 = (_co.onKeyPress($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, [["folderName", 4]], 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "folder-name"; var currVal_8 = _co.folder.name; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FolderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { nameInputRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "item-wrapper"], ["dnd-draggable", ""]], null, [[null, "onDragStart"], [null, "onDragEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDragStart" === en)) {
        var pd_0 = ((_co.isDragged = true) !== false);
        ad = (pd_0 && ad);
    } if (("onDragEnd" === en)) {
        var pd_1 = ((_co.isDragged = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "up": 0, "folder": 1 }), i1.ɵdid(4, 16384, null, 0, i4.DraggableComponent, [i1.ElementRef, i4.DragDropService, i4.DragDropConfig, i1.ChangeDetectorRef], { draggable: [0, "draggable"], dragData: [1, "dragData"], dropzones: [2, "dropzones"] }, { onDragStart: "onDragStart", onDragEnd: "onDragEnd" }), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["dnd-droppable", ""]], null, [[null, "onDropSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDropSuccess" === en)) {
        var pd_0 = (_co.fileMove(_co.folder, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.DroppableComponent, [i1.ElementRef, i4.DragDropService, i4.DragDropConfig, i1.ChangeDetectorRef], { allowdrop: [0, "allowdrop"], dropzones: [1, "dropzones"] }, { onDropSuccess: "onDropSuccess" }), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFolderClick(_co.folder) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderComponent_1)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderComponent_3)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item-wrapper"; var currVal_1 = _ck(_v, 3, 0, _co.folder.backFolder, !_co.folder.backFolder); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = true; var currVal_3 = _co.folder; var currVal_4 = _ck(_v, 5, 0, "demo1"); _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.getDropCheck(); var currVal_6 = _ck(_v, 8, 0, "demo1"); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_7 = !_co.folder.backFolder; _ck(_v, 11, 0, currVal_7); var currVal_8 = (!_co.folder.backFolder && !_co.folder.isEditing); _ck(_v, 13, 0, currVal_8); var currVal_9 = _co.folder.isEditing; _ck(_v, 15, 0, currVal_9); }, null); }
export function View_FolderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "folder", [], null, null, null, View_FolderComponent_0, RenderType_FolderComponent)), i1.ɵdid(1, 573440, null, 0, i5.FolderComponent, [i6.Router], null, null)], null, null); }
var FolderComponentNgFactory = i1.ɵccf("folder", i5.FolderComponent, View_FolderComponent_Host_0, { folder: "folder", tree: "tree" }, { onDelete: "onDelete", onFileMove: "onFileMove", onEditName: "onEditName", onExternalClick: "onExternalClick" }, []);
export { FolderComponentNgFactory as FolderComponentNgFactory };
