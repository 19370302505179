import { AppEnums } from "./app-constants";
var StorageService = /** @class */ (function () {
    function StorageService() {
        this.storage = {};
        this.type = AppEnums.storageTypes.sessionStorage;
        this.storageSupported = this._isStorageSupported(this.type);
        if (this.storageSupported) {
            this._determineCurrentStorageType();
        }
    }
    StorageService.prototype.get = function (key) {
        if (this.storage[key]) {
            return this.storage[key];
        }
        if (this.storageSupported && window[this.type][key]) {
            this.storage[key] = JSON.parse(window[this.type][key]);
        }
        return this.storage[key];
    };
    StorageService.prototype.set = function (key, data) {
        this.storage[key] = data;
        if (this.storageSupported) {
            window[this.type][key] = JSON.stringify(data);
        }
    };
    /***
     * Removes specified item from localStorage
     * @param key {string}
     */
    StorageService.prototype.remove = function (key) {
        if (this.storageSupported) {
            delete window[this.type][key];
        }
        delete this.storage[key];
    };
    ;
    StorageService.prototype.moveToStorage = function (type) {
        if (this._isStorageSupported(type)) {
            var destination = window[type], source = window[this.type];
            for (var i = 0; i < source.length; i++) {
                var key = source.key(i);
                destination.setItem(key, source.getItem(key));
                source.removeItem(key);
            }
            this.type = type;
        }
    };
    StorageService.prototype.switchToPermanentStorage = function () {
        this.moveToStorage(AppEnums.storageTypes.localStorage);
    };
    StorageService.prototype.switchToTemporaryStorage = function () {
        if (this.type !== AppEnums.storageTypes.sessionStorage) {
            this.moveToStorage(AppEnums.storageTypes.sessionStorage);
        }
    };
    StorageService.prototype._isStorageSupported = function (type) {
        return type in window && window[type] !== null && this.isLocalStorageAccessible(type);
    };
    StorageService.prototype._determineCurrentStorageType = function () {
        this.type = AppEnums.storageTypes.sessionStorage;
        for (var prop in AppEnums.storageTypes) {
            var type = AppEnums.storageTypes[prop];
            if (this._isStorageSupported(type) && Object.keys(window[type]).length) {
                this.type = type;
                break;
            }
        }
    };
    /**
     * Test set/remove from LS.
     * Required for Safari on Iphone in private mode
     * @param type
     * @returns {boolean}
     */
    StorageService.prototype.isLocalStorageAccessible = function (type) {
        var testKey = 'test';
        var storage = window[type];
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        }
        catch (error) {
            return false;
        }
    };
    return StorageService;
}());
export { StorageService };
