import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {CurrentUserService} from "./core/current-user.service";
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';

import {DropdownDirective} from 'angular-custom-dropdown';
import {AuthService} from "./auth/auth.service";
import {UserProfileDto} from "./core/app.interfaces";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('menuDropdown') menuDropdown: DropdownDirective;

  currentUser = {} as UserProfileDto;
  visible = true;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              public toastr: ToastrService,
              vcr: ViewContainerRef,
              public currentUserService: CurrentUserService,
              private authService: AuthService,
              private spinnerService: Ng4LoadingSpinnerService,
              private translate: TranslateService) {

    // this.toastr.setRootViewContainerRef(vcr);
    translate.setDefaultLang('en');

    this.router.events.subscribe(eventData => {
      // this.onRouteChange(eventData);
      if (eventData['url']){
        const firstChildren = eventData['url'].split('?')[0];
        this.visible = (firstChildren === '/login' || firstChildren === '/');
      }
    });

    this.authService.currentUser.subscribe((user: {user: UserProfileDto, locale: string})=> {
      this.currentUser = user.user;
      localStorage.setItem('locale', user.locale);
      this.translate.use(user.locale);
      this.loadPars();
    });
  }

  ngOnInit() {
    let selectedLanguage = localStorage.getItem('locale');
    if (selectedLanguage){
      this.translate.use(selectedLanguage);
    }
    if(this.currentUserService.get()) {
      this.loadPars();
    }
  }

  loadPars(){
    this.currentUser = this.currentUserService.get();
  }

  logout() {
    this.authService.logout().subscribe((result) => {
      this.currentUserService.logout();
    });
  }
}
