import {Injectable} from '@angular/core';
import {AppEnums} from "./app-constants";

@Injectable()
export class StorageService {

  private storage: any;
  private storageSupported: boolean;
  private type: string;

  constructor() {
    this.storage = {};
    this.type = AppEnums.storageTypes.sessionStorage;
    this.storageSupported = this._isStorageSupported(this.type);

    if (this.storageSupported) {
      this._determineCurrentStorageType();
    }
  }

  get(key: string) {

    if (this.storage[key]) {
      return this.storage[key];
    }

    if (this.storageSupported && window[this.type][key]) {
      this.storage[key] = JSON.parse(window[this.type][key]);
    }

    return this.storage[key];
  }

  set(key: string, data: any) {
    this.storage[key] = data;

    if (this.storageSupported) {
      window[this.type][key] = JSON.stringify(data);
    }
  }

  /***
   * Removes specified item from localStorage
   * @param key {string}
   */
  remove(key: string) {
    if (this.storageSupported) {
      delete window[this.type][key];
    }

    delete this.storage[key];
  };

  moveToStorage(type: string) {

    if (this._isStorageSupported(type)) {

      const
        destination: Storage = window[type],
        source: Storage = window[this.type];

      for (let i = 0; i < source.length; i++) {

        const key = source.key(i);

        destination.setItem(key, source.getItem(key));

        source.removeItem(key);
      }

      this.type = type;
    }
  }

  switchToPermanentStorage() {
    this.moveToStorage(AppEnums.storageTypes.localStorage);
  }

  switchToTemporaryStorage() {
    if (this.type !== AppEnums.storageTypes.sessionStorage) {
      this.moveToStorage(AppEnums.storageTypes.sessionStorage);
    }
  }

  _isStorageSupported(type: string) {
    return type in window && window[type] !== null && this.isLocalStorageAccessible(type);
  }

  _determineCurrentStorageType() {
    this.type = AppEnums.storageTypes.sessionStorage;
    for (let prop in AppEnums.storageTypes) {
      let type = AppEnums.storageTypes[prop];
      if (this._isStorageSupported(type) && Object.keys(window[type]).length) {
        this.type = type;
        break;
      }
    }
  }

  /**
   * Test set/remove from LS.
   * Required for Safari on Iphone in private mode
   * @param type
   * @returns {boolean}
   */
  isLocalStorageAccessible(type: string) {
    let testKey: string = 'test';
    let storage: any = window[type];
    try {
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
    }
    catch (error) {
      return false;
    }
  }

}
