import { OnInit } from '@angular/core';
import { PaymentService } from "./payment.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CurrentUserService } from "../core/current-user.service";
var PaymentComponent = /** @class */ (function () {
    function PaymentComponent(paymentService, route, toastr, router, spinnerService, currentUserService) {
        var _this = this;
        this.paymentService = paymentService;
        this.route = route;
        this.toastr = toastr;
        this.router = router;
        this.spinnerService = spinnerService;
        this.currentUserService = currentUserService;
        this.entity = {};
        paymentService.onCertificatePurchased.subscribe(function (event) { return _this.onCertificatePurchased(event); });
    }
    PaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paymentService.getPayment(this.route.snapshot.params['id']).subscribe(function (item) {
            _this.entity = item;
            _this.thumbnail = 'data:image/png;base64,' + _this.entity.thumbnail;
            _this.spinnerService.hide();
        }, function (response) {
            _this.toastr.error(response.error.message, '');
            _this.router.navigate(['certificates']);
            _this.spinnerService.hide();
        });
    };
    PaymentComponent.prototype.makePay = function () {
        this.paymentService.pay(this.entity.course, this.entity.priceInCents, this.entity.criteria, this.currentUserService.get().email);
    };
    PaymentComponent.prototype.onCertificatePurchased = function (event) {
        var _this = this;
        this.spinnerService.show();
        this.paymentService.sendPayment(this.entity.purchaseId, event.token)
            .subscribe(function (result) {
            _this.toastr.success('Certificate paid successfully', '');
            _this.router.navigate(['certificates']);
            _this.spinnerService.hide();
        }, function (response) {
            _this.spinnerService.hide();
            _this.toastr.error(response.error.message, '');
        });
    };
    return PaymentComponent;
}());
export { PaymentComponent };
