import {EventEmitter, Injectable} from '@angular/core';

import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ICertiificatePurchase, ITemplate} from "../core/app.interfaces";


@Injectable()
export class PaymentService {
  private handler: any;

  public onCertificatePurchased = new EventEmitter<ICertiificatePurchase>();

  constructor(private http: HttpClient) {
    this.configureStripe();
  }

  getPayment(id: string): Observable<ITemplate> {
    return this.http.get(`${environment.serverUrl}/purchases/${id}`) as Observable<ITemplate>;
  }

  configureStripe() {
    this.handler = StripeCheckout.configure({
      key: 'pk_test_qi7oi12PUlHHlAID7o666Iah',
      image: 'assets/images/doc-logo.png',
      locale: 'auto',

      name: `Student's Vault`,
      description: 'Certificate',

      token: (token:any) => {
        this.onCertificatePurchased.emit({
          token: token.id
        } as ICertiificatePurchase);
        console.log(token);
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      }
    });
  }

  pay(course: string, priceInCents: number, criteria: string, stuentName: string) {
    this.handler.open({
      email: stuentName,
      name: course,
      description: criteria,
      zipCode: false,
      amount: priceInCents,
      billingAddress: false
    });
  }

  sendPayment(purchaseId: string, token: string) {
    return this.http.post(`${environment.serverUrl}/purchases/${purchaseId}/pay`, {
      token: token
    });
  }
}
