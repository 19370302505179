import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from "../../core/current-user.service";
import { StorageService } from "../../core/storage.service";
import { AuthService } from "../auth.service";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
var ResetPasswordPageComponent = /** @class */ (function () {
    function ResetPasswordPageComponent(authService, currentUserService, storageService, activatedRoute, toastr, _router, spinnerService) {
        this.authService = authService;
        this.currentUserService = currentUserService;
        this.storageService = storageService;
        this.toastr = toastr;
        this._router = _router;
        this.spinnerService = spinnerService;
        this.$reseted = false;
        this.entity = {};
        this.spinnerService.hide();
    }
    ResetPasswordPageComponent.prototype.submit = function () {
        var _this = this;
        this.$submitted = true;
        if (!this.editForm.valid) {
            return;
        }
        this.requestProcessing = true;
        this.spinnerService.show();
        this.authService.forgotPassword({
            email: this.entity.email
        }).subscribe(function (result) {
            _this.requestProcessing = false;
            _this.$reseted = true;
            _this.spinnerService.hide();
        }, function (error) {
            var msg = error.status === 401 ? error.error['message'] : "Something wrong.";
            _this.toastr.error(msg);
            _this.requestProcessing = false;
            _this.spinnerService.hide();
        }, function () {
            _this.requestProcessing = false;
        });
    };
    ResetPasswordPageComponent.prototype.cancel = function () {
        this._router.navigate(['login']);
    };
    return ResetPasswordPageComponent;
}());
export { ResetPasswordPageComponent };
