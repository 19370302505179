import { CurrentUserService } from "./core/current-user.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
var CanActivateApp = /** @class */ (function () {
    function CanActivateApp(currentUserService, spinnerService) {
        this.currentUserService = currentUserService;
        this.spinnerService = spinnerService;
    }
    CanActivateApp.prototype.canActivate = function (route, state) {
        if (this.currentUserService.isLoggedIn()) {
            return true;
        }
        else {
            this.currentUserService.redirectToLoginPage(state.url);
            return false;
        }
    };
    return CanActivateApp;
}());
export { CanActivateApp };
