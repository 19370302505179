import { OnInit } from '@angular/core';
import { PurchasesService } from "./purchases.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
var PurchasesComponent = /** @class */ (function () {
    function PurchasesComponent(purchasesService, spinnerService) {
        this.purchasesService = purchasesService;
        this.spinnerService = spinnerService;
        this.purchases = [];
    }
    PurchasesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.purchasesService.getPurchases()
            .subscribe(function (items) {
            _this.purchases = items;
            _this.spinnerService.hide();
        }, function (error) {
            _this.spinnerService.hide();
        });
    };
    return PurchasesComponent;
}());
export { PurchasesComponent };
