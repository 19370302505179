import {Injectable, EventEmitter} from "@angular/core";

import {StorageService} from "./storage.service";
import {AppEnums} from "./app-constants";

@Injectable()
export class AuthStateService {

  private token: string;

  public onTokenClear = new EventEmitter<any>();

  constructor(private storageService: StorageService) {
    this.token = this.storageService.get(AppEnums.storageKeys.XAuthToken);
  }

  public setToken(token: string) {
    this.token = token;
    if (token !== null) {
      this.storageService.set(AppEnums.storageKeys.XAuthToken, token);
    } else {
      this.storageService.remove(AppEnums.storageKeys.XAuthToken);
    }
  }

  public getToken() {
    return this.token;
  }

  public clearToken() {

    if (this.token === null) return;

    this.token = null;
    this.storageService.remove(AppEnums.storageKeys.XAuthToken);
    this.onTokenClear.emit(null);
  }

}
