<div class="item-wrapper"
     [ngClass]="{'up': folder.backFolder, 'folder': !folder.backFolder}"
     dnd-draggable
     [dragEnabled]="true"
     [dropZones]="['demo1']"
     [dragData]="folder"
     (onDragStart)="isDragged = true"
     (onDragEnd)="isDragged = false">

  <div dnd-droppable (onDropSuccess)="fileMove(folder, $event)" [dropZones]="['demo1']" [allowDrop]="getDropCheck()">
    <div class="item" (click)="onFolderClick(folder)">
      <div class="item-btn-wrap" *ngIf="!folder.backFolder">
        <div>
          <button class="btn btn-edit" type="button" (click)="editName($event)"></button>
          <button class="btn btn-del" type="button" (click)="deleteFolder($event)"></button>
        </div>
      </div>

      <div class="item-file-name" *ngIf="!folder.backFolder && !folder.isEditing">
        {{folder.name}}
      </div>

      <div class="item-file-name" *ngIf="folder.isEditing">
        <input #nameInput type="text" id="folder-name" name="folder-name" [(ngModel)]="folder.name"
               #folderName="ngModel" (click)="$event.stopPropagation()" (keyup.enter)="onKeyPress($event)"/>
      </div>
    </div>
  </div>

</div>
