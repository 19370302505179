import {Component, OnInit} from '@angular/core';

import {environment} from '../../environments/environment';
import {PurchasesService} from "./purchases.service";
import {IPurchase} from "../core/app.interfaces";
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';

@Component({
  selector: 'purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent implements OnInit {

  public purchases: Array<IPurchase> = [];

  constructor(private purchasesService: PurchasesService,
              private spinnerService: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
    this.purchasesService.getPurchases()
      .subscribe((items) => {
        this.purchases = items;
        this.spinnerService.hide();
      }, (error)=>{
        this.spinnerService.hide();
      });
  }

}
