<main class="middle">
  <div class="welcome-container">
    <div class="content">
      <div class="center">
        <img src="assets/images/doc-logo.png" alt="">
      </div>
      <h3>{{'login.title' | translate}}</h3>
      <div class="row">
        <div class="welcome-form" >
          <form #editForm="ngForm" autocomplete="off" (keydown.enter)="login()">

            <div class="form-group">
              <label for="email">{{'login.email.label' | translate}}:</label>
              <div class="form-control">
                <input type="email" id="email" name="email" [(ngModel)]="entity.email" #email="ngModel"
                       placeholder="{{'login.email.placeholder' | translate}}"
                       maxlength="100" autocomplete="off" required>
                <div *ngIf="email.errors && $submitted">
                  <p [hidden]="!email.errors.required" class="validation-error">
                    {{'login.email.required-error' | translate}}
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">{{'login.password.label' | translate}}:</label>
              <div class="form-control">
                <input id="password" name="password" [(ngModel)]="entity.password" #password="ngModel"
                       maxlength="100" placeholder="{{'login.password.placeholder' | translate}}" type="password" autocomplete="off" required>
                <div *ngIf="password.errors && $submitted">
                  <p [hidden]="!password.errors.required" class="validation-error">
                    {{'login.password.required-error' | translate}}
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-control">
                <p>
                  {{'login.reset-label' | translate}} <a routerLink="/reset-password">{{'login.reset-link' | translate}}</a>.
                </p>
              </div>
            </div>
            <div class="btn-group">
              <button class="btn btn-login" type="submit" (click)="login()"
                      [disabled]="requestProcessing">
                <i class="fa fa-spinner fa-spin" [hidden]="!requestProcessing"></i>
                {{'login.submit-button' | translate}}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</main>
