import { Routes, ExtraOptions } from '@angular/router';
import { PaymentComponent } from "./payment/payment.component";
import { CertificatesComponent } from "./certificates/certificates.component";
import { PurchasesComponent } from "./purchases/purchases.component";
import { CanActivateApp } from "./app-activate";
import { LoginPageComponent } from "./auth/login/login.component";
import { PasswordPageComponent } from "./auth/password/password.component";
import { ResetPasswordPageComponent } from "./auth/reset-password/reset-password.component";
import { ProfileComponent } from "./profile/profile.component";
var routes = [
    {
        path: ':id/payment',
        component: PaymentComponent,
        canActivate: [CanActivateApp]
    },
    {
        path: 'certificates',
        component: CertificatesComponent,
        canActivate: [CanActivateApp],
    },
    {
        path: 'certificates/:folderId',
        component: CertificatesComponent,
        canActivate: [CanActivateApp],
    },
    {
        path: 'purchases',
        component: PurchasesComponent,
        canActivate: [CanActivateApp]
    },
    {
        path: 'login',
        component: LoginPageComponent
    },
    {
        path: 'password/:token',
        component: PasswordPageComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordPageComponent
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [CanActivateApp]
    },
    {
        path: '',
        redirectTo: 'certificates',
        pathMatch: 'full'
    }
];
var config = {
    useHash: true,
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
