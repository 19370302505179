import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, ValidationErrors, Validators} from "@angular/forms";
import {ChangePasswordDto, UserProfileDto} from "../core/app.interfaces";
import {HttpErrorResponse} from "@angular/common/http";
import {Ng4LoadingSpinnerService} from "ng4-loading-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthStateService} from "../core/auth-state.service";
import {ToastrService} from "ngx-toastr";
import {CurrentUserService} from "../core/current-user.service";
import {AuthService} from "../auth/auth.service";
import {StorageService} from "../core/storage.service";
import * as jwtDecode from 'jwt-decode';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {ProfileService} from "./profile.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public currentUser: UserProfileDto = {
    name: '',
    email: '',
    language: this.translate.currentLang,
    organization: ''
  };
  public requestProcessing: boolean;
  public $submitted: boolean;
  public entity: any = {};


  public selectedLanguage: string;

  public form: FormGroup;
  public formProfile: FormGroup;
  protected formBuilder: FormBuilder;

  constructor(
              private authService: AuthService,
              private currentUserService: CurrentUserService,
              private storageService: StorageService,
              private activatedRoute: ActivatedRoute,
              public toastr: ToastrService,
              private authStateService: AuthStateService,
              private router: Router,
              private spinnerService: Ng4LoadingSpinnerService,
              private translate: TranslateService,
              private profileService: ProfileService) {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });


    const injector = Injector.create([{provide: FormBuilder, deps:[]}]);
    this.formBuilder = injector.get(FormBuilder);
    this.form = this.initializeForm();
    this.formProfile = this.formBuilder.group({
      'language': [''],
      'name': [''],
      'email': ['']
    });
    this.spinnerService.hide();
  }

  ngOnInit() {
    this.profileService.getUserProfile().subscribe((user) => {
      this.currentUser = user;
      this.selectedLanguage = user.language;
      this.formProfile.patchValue({'name':this.currentUser.name});
      this.formProfile.patchValue({'email':this.currentUser.email});
    }, (error: HttpErrorResponse) => {
      const errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
      console.log(errorMsg);
    });
  }

  changeLanguage($event: Object) {
    this.selectedLanguage = $event['value'];
  }

  save() {
    this.$submitted = true;
    if (!this.form.valid) {
      return;
    }
    this.requestProcessing = true;
    this.authService.changePassword({
      password: this.entity.password,
      confirmPassword: this.entity.confirmPassword
    } as ChangePasswordDto).subscribe((result) => {
      this.toastr.info('Password was changed successfully!');

      this.requestProcessing = false;
    }, (error: HttpErrorResponse) => {
      let msg = (error.error?error.error['message']:undefined) || error['message'] || 'Something wrong.';
      this.toastr.error(msg);
      this.requestProcessing = false;
    });
  }

  submitProfile(form: UserProfileDto) {
    this.translate.use(this.selectedLanguage);
    const profile = {name: form.name, email: form.email, language: form.language};
    this.profileService.updateUserProfile(profile).subscribe((result)=>{
      localStorage.setItem('locale', result.language);

      let user = this.currentUserService.get();
      user.name = result.name;
      this.currentUserService.set({user: user});

    })

  }

  protected initializeForm(): FormGroup {
    const newPassword = this.formBuilder.control("", [Validators.required]);
    const confirmPassword = this.formBuilder.control("", [Validators.required, this.equaledPasswordsValidator.bind(this)]);

    /**
     * Update Validation when new password has changes
     */
    newPassword.valueChanges.subscribe(() => {
      confirmPassword.updateValueAndValidity();
    });

    return this.formBuilder.group({password: newPassword, confirmPassword: confirmPassword});
  }

  private equaledPasswordsValidator(control: FormControl): ValidationErrors {

    if (!control.parent) return null;
    const firstCtrl = control.parent.controls['password'];
    if (!control.value || !firstCtrl || !firstCtrl.value) return null;
    const isValid = firstCtrl.value === control.value;
    return isValid ? null : {notEquals: "Passwords aren't equal"};
  }
}
