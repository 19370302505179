import {Component, Injectable, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from "@angular/common/http";
import {CurrentUserService} from "../../core/current-user.service";
import {StorageService} from "../../core/storage.service";
import {AuthService} from "../auth.service";
import {NgForm} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {ProfileService} from "../../profile/profile.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [],
})
@Injectable()
export class LoginPageComponent {
  public requestProcessing: boolean;
  public $submitted: boolean;
  public entity: any = {};

  @ViewChild('editForm') public editForm: NgForm;


  constructor(private authService: AuthService,
              private currentUserService: CurrentUserService,
              private storageService: StorageService,
              private activatedRoute: ActivatedRoute,
              public toastr: ToastrService,
              private router: Router,
              private spinnerService: Ng4LoadingSpinnerService,
              private profileService: ProfileService) {
    this.spinnerService.hide();
  }

  ngOnInit(){
    this.spinnerService.hide();
  }

  login() {
    this.$submitted = true;

    if (!this.editForm.valid) {
      return;
    }

    this.requestProcessing = true;

    this.authService.login({
      email: this.entity.email,
      password: this.entity.password
    }).subscribe((result) => {

      const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

      if (result.pswneedchange) {
        this.currentUserService.redirectToChangePassword(result.token, returnUrl);
        return;
      }

      this.currentUserService.set(result);
      this.profileService.getUserProfile().subscribe((value) => {
        this.authService.currentUser.emit({user: result.user, locale: value.language});
        this.requestProcessing = false;
      }, (error)=> {
        console.log(error); // TODO: handle error;
      });

      if(returnUrl){
        this.router.navigateByUrl(returnUrl);
      }else{
        this.currentUserService.redirectToDefaultPage();
      }

    }, (error: HttpErrorResponse) => {
      let msg = error.status === 401 ? error.error['message'] : "Something wrong.";
      this.toastr.error(msg);
      this.requestProcessing = false;
    }, () => {
      this.requestProcessing = false;
    });
  }

  cancel() {
  }
}
