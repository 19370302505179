import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificatesService } from './certificates.service';
import { environment } from '../../environments/environment';
import { CurrentUserService } from '../core/current-user.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AppEnums } from '../core/app-constants';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
var CertificatesComponent = /** @class */ (function () {
    function CertificatesComponent(certificatesService, route, router, currentUserService, spinnerService, toastr) {
        this.certificatesService = certificatesService;
        this.route = route;
        this.router = router;
        this.currentUserService = currentUserService;
        this.spinnerService = spinnerService;
        this.toastr = toastr;
        this.certificates = [];
        this.folders = [];
        this.tree = [];
        this.isDragged = false;
    }
    CertificatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.getPurchasedCertificates(_this.route.snapshot.params['folderId']);
        });
    };
    CertificatesComponent.prototype.openCertificate = function (item) {
        window.open(environment.annUrl + '/' + item.document, 'blank');
    };
    CertificatesComponent.prototype.buyCertificate = function (item) {
        return this.router.navigate([item.purchaseId, 'payment']);
    };
    CertificatesComponent.prototype.onFileMove = function (folder, $event) {
        var _this = this;
        if ($event.event.dragData.id === folder.id)
            return;
        this.spinnerService.show();
        var folderId = folder.backFolder ? this.tree[this.tree.length - 1].parentId : folder.id;
        if ($event.event.dragData.document) { // TODO: add explicit type fields
            this.certificatesService.moveToFolder($event.event.dragData.purchaseId, folderId)
                .subscribe(function (result) {
                var index = _this.certificates.indexOf($event.event.dragData);
                if (index !== -1) {
                    _this.certificates.splice(index, 1);
                }
                _this.spinnerService.hide();
            }, function (response) {
                _this.spinnerService.hide();
                _this.toastr.error(response.error.message, '');
            });
        }
        else if ($event.event.dragData.name) {
            //moveToFolder(what, where)
            this.certificatesService.moveFolder($event.event.dragData.id, folderId)
                .subscribe(function (result) {
                _.remove(_this.folders, function (folder) {
                    return folder.id === $event.event.dragData.id;
                });
                _this.spinnerService.hide();
            }, function (error) {
                _this.spinnerService.hide();
                _this.toastr.error(error.error.message, '');
            });
        }
        else {
            this.toastr.error('Unknown object type', '');
        }
    };
    CertificatesComponent.prototype.editName = function (item, $event) {
        this.unfocusRename(item);
        item.isEditing = true;
        this.selectedFolder = item;
    };
    CertificatesComponent.prototype.unfocusRename = function (item) {
        this.folders.forEach(function (i) {
            if (i.id !== item.id)
                i.isEditing = false;
        });
    };
    CertificatesComponent.prototype.addFolder = function () {
        this.selectedFolder = {
            parentId: (this.tree.length > 0) ? this.tree[this.tree.length - 1].id : null,
            isEditing: true,
            name: ''
        };
        this.folders.push(this.selectedFolder);
    };
    CertificatesComponent.prototype.onExternalClick = function () {
        if (this.selectedFolder) {
            this.selectedFolder.isEditing = false;
            this.save(this.selectedFolder);
        }
    };
    CertificatesComponent.prototype.save = function (folder) {
        var _this = this;
        this.selectedFolder = null;
        folder.isEditing = false;
        this.certificatesService.addFolder(folder.name, folder.id, folder.parentId).subscribe(function (result) {
            folder.id = result.id;
        }, function (response) {
            _this.toastr.error(response.error.message, '');
        });
    };
    CertificatesComponent.prototype.onDelete = function ($event) {
        var _this = this;
        this.spinnerService.show();
        this.certificatesService.deleteFolder($event.id).subscribe(function (result) {
            _.remove(_this.folders, function (folder) {
                return folder.id === $event.id;
            });
            _this.spinnerService.hide();
        }, function (response) {
            _this.spinnerService.hide();
            _this.toastr.error(response.error.message, '');
        });
    };
    CertificatesComponent.prototype.getPurchasedCertificates = function (folderId) {
        var _this = this;
        this.certificatesService.getPurchased(folderId)
            .subscribe(function (response) {
            var items = response.items;
            _this.certificates = [];
            _this.folders = [];
            _this.tree = response.tree.reverse();
            if (folderId) {
                _this.folders.push({
                    id: response.tree.length > 1 ? response.tree[0].id : null,
                    backFolder: true
                });
            }
            items.forEach(function (item) {
                if (item.type === AppEnums.fileType.certificate) {
                    item.entity.thumbnail = 'data:image/png;base64,' + item.entity.thumbnail;
                    _this.certificates.push(item.entity);
                }
                else {
                    _this.folders.push(item.entity);
                }
            });
            _this.spinnerService.hide();
        }, function (error) {
            _this.spinnerService.hide();
        });
    };
    return CertificatesComponent;
}());
export { CertificatesComponent };
