import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { ReflectiveInjector } from "@angular/core";
import { CurrentUserService } from "../../core/current-user.service";
import { AuthService } from "../auth.service";
var PasswordPageComponent = /** @class */ (function () {
    function PasswordPageComponent(authService, currentUserService, activatedRoute, toastr, router, spinnerService) {
        this.authService = authService;
        this.currentUserService = currentUserService;
        this.activatedRoute = activatedRoute;
        this.toastr = toastr;
        this.router = router;
        this.spinnerService = spinnerService;
        this.entity = {};
        var injector = ReflectiveInjector.resolveAndCreate([FormBuilder]);
        this.formBuilder = injector.get(FormBuilder);
        this.form = this.initializeForm();
        this.spinnerService.hide();
    }
    PasswordPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.token = this.activatedRoute.snapshot.params['token'];
        if (!this.token || this.token === '') {
            this.router.navigate(['login']);
        }
        this.authService.getResetPassword({ token: this.token }).subscribe(function (entity) {
            _this.entity = entity;
            _this.spinnerService.hide();
        }, function (error) {
            _this.spinnerService.hide();
            var msg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.toastr.error(msg);
            _this.router.navigate(['login']);
        });
    };
    PasswordPageComponent.prototype.save = function () {
        var _this = this;
        this.$submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.requestProcessing = true;
        this.authService.postResetPassword({ token: this.token }, {
            password: this.entity.password,
            confirmPassword: this.entity.confirmPassword
        }).subscribe(function (result) {
            _this.currentUserService.set(result);
            var returnUrl = _this.activatedRoute.snapshot.queryParams['returnUrl'];
            if (returnUrl) {
                _this.router.navigateByUrl(returnUrl);
            }
            else {
                _this.currentUserService.redirectToDefaultPage();
            }
            _this.requestProcessing = false;
        }, function (error) {
            var msg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.toastr.error(msg);
            _this.requestProcessing = false;
        });
    };
    PasswordPageComponent.prototype.cancel = function () {
        this.router.navigate(['login']);
    };
    PasswordPageComponent.prototype.initializeForm = function () {
        var newPassword = this.formBuilder.control("", [Validators.required]);
        var confirmPassword = this.formBuilder.control("", [Validators.required, this.equaledPasswordsValidator.bind(this)]);
        /**
         * Update Validation when new password has changes
         */
        newPassword.valueChanges.subscribe(function () {
            confirmPassword.updateValueAndValidity();
        });
        return this.formBuilder.group({ password: newPassword, confirmPassword: confirmPassword });
    };
    PasswordPageComponent.prototype.equaledPasswordsValidator = function (control) {
        if (!control.parent)
            return null;
        var firstCtrl = control.parent.controls['password'];
        if (!control.value || !firstCtrl || !firstCtrl.value)
            return null;
        var isValid = firstCtrl.value === control.value;
        return isValid ? null : { notEquals: "Passwords aren't equal" };
    };
    return PasswordPageComponent;
}());
export { PasswordPageComponent };
