<div class="subheader">
  <div class="breadcrumbs">
    <ul>
      <li>
        <span *ngIf="tree.length == 0">
          {{'certificates.title' | translate}}
        </span>
        <span *ngIf="tree.length">
          <a routerLink="/">{{'certificates.title-link' | translate}}</a>
        </span>
      </li>
      <li *ngFor="let folder of tree">
        <a [routerLink]="['/certificates', folder.id]">{{folder.name}}</a>
      </li>
    </ul>
  </div>
  <button type="button" class="add-folder" (click)="addFolder()"></button>
</div>
<main class="middle" (click)="onExternalClick()">
  <div class="auto-scroll">
    <div class="content">
      <div class="container-max">
        <div class="row">
          <div class="template-list" >

            <folder *ngFor="let folder of folders"
                    [folder]="folder"
                    [tree]="tree"
                    (onDelete)="onDelete($event)"
                    (onFileMove)="onFileMove(folder, $event)"
                    (onEditName)="editName(folder, $event)"
                    (onExternalClick)="onExternalClick()">
            </folder>

            <div class="item-wrapper" *ngFor="let item of certificates; let i = index" dnd-draggable
                 [dragEnabled]="true" [dragData]="item" [dropZones]="['demo1']">
              <div class="item">
                <img class="item-view" alt="template view" [src]="item.thumbnail"/>
                <div class="item-btn-wrap" >
                  <button class="btn btn-share" (click)="openCertificate(item)" *ngIf="item.isPaid"></button>
                  <button class="btn btn-buy" (click)="buyCertificate(item)" *ngIf="!item.isPaid"></button>
                </div>
                <div class="item-file-name">
                  <time datetime="">{{item.issuingDate}}</time>
                  <span class="name">{{item.course}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
