import {Component, Injectable, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from "@angular/common/http";
import {CurrentUserService} from "../../core/current-user.service";
import {StorageService} from "../../core/storage.service";
import {AuthService} from "../auth.service";
import {NgForm} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [],
})
@Injectable()
export class ResetPasswordPageComponent {
  public requestProcessing: boolean;
  public $submitted: boolean;
  public $reseted = false;
  public entity: any = {};

  @ViewChild('editForm') public editForm: NgForm;


  constructor(private authService: AuthService,
              private currentUserService: CurrentUserService,
              private storageService: StorageService,
              activatedRoute: ActivatedRoute,
              public toastr: ToastrService,
              private _router: Router,
              private spinnerService: Ng4LoadingSpinnerService) {
    this.spinnerService.hide();
  }

  submit() {
    this.$submitted = true;

    if (!this.editForm.valid) {
      return;
    }

    this.requestProcessing = true;
    this.spinnerService.show();

    this.authService.forgotPassword({
      email: this.entity.email
    }).subscribe((result) => {
      this.requestProcessing = false;
      this.$reseted = true;
      this.spinnerService.hide();
    }, (error: HttpErrorResponse) => {
      let msg = error.status === 401 ? error.error['message'] : "Something wrong.";
      this.toastr.error(msg);
      this.requestProcessing = false;
      this.spinnerService.hide();
    }, () => {
      this.requestProcessing = false;
    });
  }

  cancel() {
    this._router.navigate(['login']);
  }
}
