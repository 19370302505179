import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
var CertificatesService = /** @class */ (function () {
    function CertificatesService(http) {
        this.http = http;
    }
    CertificatesService.prototype.getPurchased = function (folderId) {
        if (folderId) {
            return this.http.get(environment.serverUrl + "/students/certificates/" + folderId);
        }
        else {
            return this.http.get(environment.serverUrl + "/students/certificates");
        }
    };
    CertificatesService.prototype.addFolder = function (name, id, parentId) {
        if (id) {
            return this.http.put(environment.serverUrl + "/folders/update/" + id, {
                name: name
            });
        }
        else {
            return this.http.post(environment.serverUrl + "/folders/create", {
                name: name,
                parentId: parentId
            });
        }
    };
    CertificatesService.prototype.moveFolder = function (moveFolder, parentFolderId) {
        return this.http.put(environment.serverUrl + "/folders/update/" + moveFolder, {
            parentId: parentFolderId
        });
    };
    CertificatesService.prototype.moveToFolder = function (certificateId, folderId) {
        return this.http.post(environment.serverUrl + "/students/certificates/" + certificateId + "/move-to-folder", {
            id: folderId
        });
    };
    CertificatesService.prototype.deleteFolder = function (id) {
        return this.http.delete(environment.serverUrl + "/folders/delete/" + id);
    };
    return CertificatesService;
}());
export { CertificatesService };
