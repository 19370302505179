import {Component, Injectable} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from "@angular/common/http";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {FormBuilder, FormControl, FormGroup, NgForm, ValidationErrors, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {ReflectiveInjector} from "@angular/core";
import {CurrentUserService} from "../../core/current-user.service";
import {AuthService} from "../auth.service";
import {ChangePasswordDto} from "../../core/app.interfaces";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: [],
})
@Injectable()
export class PasswordPageComponent {
  public requestProcessing: boolean;
  public $submitted: boolean;
  public entity: any = {};
  private token: string;

  public form: FormGroup;
  protected formBuilder: FormBuilder;

  constructor(private authService: AuthService,
              private currentUserService: CurrentUserService,
              private activatedRoute: ActivatedRoute,
              public toastr: ToastrService,
              private router: Router,
              private spinnerService: Ng4LoadingSpinnerService) {

    const injector = ReflectiveInjector.resolveAndCreate([FormBuilder]);
    this.formBuilder = injector.get(FormBuilder);
    this.form = this.initializeForm();
    this.spinnerService.hide();
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params['token'];
    if (!this.token || this.token === '') {
      this.router.navigate(['login']);
    }
    this.authService.getResetPassword({token: this.token}).subscribe((entity) => {
      this.entity = entity;
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      let msg = (error.error?error.error['message']:undefined) || error['message'] || 'Something wrong.';
      this.toastr.error(msg);
      this.router.navigate(['login']);
    });

  }

    save() {
    this.$submitted = true;

    if (!this.form.valid) {
      return;
    }

    this.requestProcessing = true;

    this.authService.postResetPassword({token:this.token}, {
      password: this.entity.password,
      confirmPassword: this.entity.confirmPassword
    } as ChangePasswordDto).subscribe((result) => {
      this.currentUserService.set(result);
      const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        this.currentUserService.redirectToDefaultPage();
      }

      this.requestProcessing = false;
    }, (error: HttpErrorResponse) => {
      let msg = (error.error?error.error['message']:undefined) || error['message'] || 'Something wrong.';
      this.toastr.error(msg);
      this.requestProcessing = false;
    });
  }

  cancel() {
    this.router.navigate(['login']);
  }

  protected initializeForm(): FormGroup {
    const newPassword = this.formBuilder.control("", [Validators.required]);
    const confirmPassword = this.formBuilder.control("", [Validators.required, this.equaledPasswordsValidator.bind(this)]);

    /**
     * Update Validation when new password has changes
     */
    newPassword.valueChanges.subscribe(() => {
      confirmPassword.updateValueAndValidity();
    });

    return this.formBuilder.group({password: newPassword, confirmPassword: confirmPassword});
  }

  private equaledPasswordsValidator(control: FormControl): ValidationErrors {

    if (!control.parent) return null;

    const firstCtrl = control.parent.controls['password'];

    if (!control.value || !firstCtrl || !firstCtrl.value) return null;

    const isValid = firstCtrl.value === control.value;

    return isValid ? null : {notEquals: "Passwords aren't equal"};
  }

}
