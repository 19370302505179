<main class="middle pay-page">
  <div class="content">
    <h2>{{'payment.title' | translate}}</h2>
    <div class="contant-inner" [hidden]="!entity.purchaseId">
      <div class="preview-container">
        <div class="inner">
          <img [src]="thumbnail" alt="">
        </div>
      </div>
      <div class="preview-description">
        <div class="price-container">
          <div class="price">{{entity.price | number:'1.2-2'}} {{entity.currency || 'usd' | uppercase}}</div>
          <button class="btn" (click)="makePay()" [disabled]="entity.isPaid" [hidden]="!entity.purchaseId">
            <span *ngIf="entity.isPaid">{{'payment.paid-button' | translate}}</span>
            <span *ngIf="!entity.isPaid">{{'payment.pay-button' | translate}}</span>
          </button>

        </div>
        <dl>
          <dt>{{'payment.certificate.name' | translate}}:</dt>
          <dd>{{entity.course}}</dd>
          <dt>{{'payment.certificate.date' | translate}}:</dt>
          <dd>{{entity.issuingDate | date:'yyyy-MM-dd'}}</dd>
          <dt>{{'payment.certificate.criteria' | translate}}:</dt>
          <dd>
            <p>
              {{entity.criteria}}
            </p>
          </dd>
        </dl>
      </div>
      <div style="position: relative;  margin-left: 380px;">
        <p>{{'payment.certificate.notes' | translate}}</p>
      </div>

    </div>
  </div>
</main>
