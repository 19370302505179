<main class="middle">
  <div class="welcome-container">
    <div class="content">
      <h3>{{'reset-password.title' | translate}}</h3>
      <div class="row">
        <div class="welcome-form">
          <form #editForm="ngForm" autocomplete="off">

            <div class="form-group" [hidden]="$reseted">
              <label for="email">{{'reset-password.email.label' | translate}}:</label>
              <div class="form-control">
                <input type="email" id="email" name="email" [(ngModel)]="entity.email" #email="ngModel"
                       placeholder="{{'reset-password.email.placeholder' | translate}}"
                       maxlength="100" autocomplete="off" required>
                <div *ngIf="email.errors && $submitted">
                  <p [hidden]="!email.errors.required" class="validation-error">
                    {{'reset-password.email.required-error' | translate}}
                  </p>
                </div>
              </div>
            </div>

            <div [hidden]="!$reseted">
              {{'reset-password.info' | translate:{email: entity.email} }}
            </div>

            <div class="btn-group">
              <div [hidden]="$reseted">
                <button class="btn btn-submit" type="submit" (click)="submit()"
                      [disabled]="requestProcessing" style="width: 150px;">
                  <i class="fa fa-spinner fa-spin" [hidden]="!requestProcessing"></i>
                  {{'reset-password.submit-button' | translate}}
                </button>
              </div>
              <div [hidden]="$reseted">
                <button class="btn btn-cancel" (click)="cancel()" style="width: 150px;">
                  {{'reset-password.back-button' | translate}}
                </button>
              </div>
              <div [hidden]="!$reseted">
                <button class="btn btn-cancel" (click)="cancel()" style="width: 150px;">
                  {{'reset-password.to-login-button' | translate}}
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</main>
