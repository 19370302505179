var SingleRequestService = /** @class */ (function () {
    function SingleRequestService() {
        this.entity = null;
        this._loading = false;
        this._pendingPromises = [];
    }
    SingleRequestService.prototype.getList = function () {
        return Promise.reject("no resource");
    };
    SingleRequestService.prototype.load = function () {
        var _this = this;
        var pending = this.buildDeffered();
        if (this.entity == null) {
            this._pendingPromises.push(pending);
            if (this._loading === false) {
                this._loading = true;
                this.getList()
                    .then(function (successResult) {
                    _this._loading = false;
                    _this._setEntity(successResult);
                    _this._resolve(_this.entity);
                }, function (error) {
                    _this._loading = false;
                    for (var i = 0; i < _this._pendingPromises.length; ++i) {
                        _this._pendingPromises[i].reject(error);
                    }
                    _this._pendingPromises = [];
                });
            }
        }
        else if (this._loading === false) {
            pending.resolve(this.entity);
        }
        return pending.promise;
    };
    SingleRequestService.prototype.set = function (entity) {
        this.entity = entity;
    };
    SingleRequestService.prototype.get = function () {
        return this.entity;
    };
    SingleRequestService.prototype._resolve = function (entity) {
        for (var i = 0; i < this._pendingPromises.length; ++i) {
            this._pendingPromises[i].resolve(entity);
        }
        this._pendingPromises = [];
    };
    SingleRequestService.prototype._setEntity = function (result) {
        this.entity = result.data;
    };
    SingleRequestService.prototype.buildDeffered = function () {
        var deffer = {};
        deffer.promise = new Promise(function (resolve, reject) {
            deffer.resolve = resolve;
            deffer.reject = reject;
        });
        return deffer;
    };
    return SingleRequestService;
}());
export { SingleRequestService };
