<div class="wrapper">
  <ng4-loading-spinner style="display: block !important;" [threshold]="0"> </ng4-loading-spinner>
  <header class="header">
    <div class="container">
      <div class="logo">
        <a routerLink="/certificates"></a>
      </div>
      <div class="st-name" *ngIf="!visible">
          <span>
            {{currentUser.name}}
          </span>
        <div class="menu-wrap" dropdown #menuDropdown=dropdown >
          <button class="menu-btn" dropdownToggle>
            <svg fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
          </button>
          <div class="user-menu" dropdownMenu>
            <ul>
              <li>
                <a routerLink="/profile">
                  <svg fill="#555" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                  <span>{{'app-root.menu.account' | translate}}</span>
                </a>
              </li>
              <li>
                <a href="#" (click)="logout()">
                  <svg fill="#555555" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 210004" xml:space="preserve">
											<g><path d="M584.7,881.1H146.9V118.9h437.7v163.3h107.8V118.9C692.5,59,643.5,10,583.6,10H148C88.1,10,39.1,59,39.1,118.9v762.2C39.1,941,88.1,990,148,990h435.6c59.9,0,108.9-49,108.9-108.9V772.2H584.7V881.1L584.7,881.1z M960.9,527.2L744.2,314.9v130.7h-369v163.3h369v130.7L960.9,527.2L960.9,527.2z"></path></g>
										</svg>
                  <span>{{'app-root.menu.logout' | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>

  </header>

  <router-outlet></router-outlet>
</div>
<footer class="footer">
  <div class="container">
    <ul class="contacts">
      <li>{{'app-root.footer.contact-email' | translate}}: support@documentorum.com</li>
      <li>{{'app-root.footer.contact-phone' | translate}}: 1-949-123-45-67</li>
      <li>Skype: documentorum-support</li>
    </ul>
  </div>
</footer>
