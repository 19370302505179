var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { SingleRequestService } from "./single-request.service";
import { StorageService } from "./storage.service";
import { AuthStateService } from "./auth-state.service";
import { AppEnums } from "./app-constants";
var CurrentUserService = /** @class */ (function (_super) {
    __extends(CurrentUserService, _super);
    function CurrentUserService(storageService, authStateService, _router) {
        var _this = _super.call(this) || this;
        _this.storageService = storageService;
        _this.authStateService = authStateService;
        _this._router = _router;
        _this.onUserLogout = new EventEmitter();
        CurrentUserService.user = _this.storageService.get(AppEnums.storageKeys.User);
        _this.authStateService.onTokenClear.subscribe(function () { return _this.clear(); });
        return _this;
    }
    CurrentUserService.prototype.set = function (userDetails) {
        CurrentUserService.user = userDetails.user;
        this.storageService.set(AppEnums.storageKeys.User, CurrentUserService.user);
        if (userDetails.token) {
            this.authStateService.setToken(userDetails.token);
        }
    };
    CurrentUserService.prototype.get = function () {
        return CurrentUserService.user;
    };
    CurrentUserService.prototype.getRoleType = function () {
        return this.isLoggedIn() ? CurrentUserService.user.role : null;
    };
    CurrentUserService.prototype.isLoggedIn = function () {
        return !!CurrentUserService.user;
    };
    CurrentUserService.getDefaultPageUrl = function () {
        if (!CurrentUserService.user)
            return '/login';
        return "/certificates";
    };
    CurrentUserService.prototype.redirectToLoginPage = function (returnUrl) {
        var navigationExtras = {
            queryParams: { returnUrl: returnUrl }
        };
        this._router.navigate(['login'], navigationExtras);
    };
    CurrentUserService.prototype.redirectToDefaultPage = function () {
        this._router.navigate(['/certificates']);
    };
    CurrentUserService.prototype.redirectToChangePassword = function (token, returnUrl) {
        var navigationExtras = {
            queryParams: { 'session_id': token, returnUrl: returnUrl }
        };
        this._router.navigate(['account'], navigationExtras);
    };
    CurrentUserService.prototype.clear = function () {
        if (CurrentUserService.user === null)
            return;
        CurrentUserService.user = null;
        this.storageService.remove(AppEnums.storageKeys.User);
        this.onUserLogout.emit(null);
    };
    CurrentUserService.prototype.logout = function () {
        this.clear();
        this.redirectToLoginPage(null);
    };
    return CurrentUserService;
}(SingleRequestService));
export { CurrentUserService };
