<main class="middle">
  <div class="account-container">
    <div class="content">
      <h3 style="text-align: center">{{'profile.title' | translate}}</h3>
      <div class="user-info">
        <form [formGroup]="formProfile" autocomplete="off" >
          <dl class="inline">
            <dt>{{'profile.profile-form.name' | translate}}:</dt>
            <dd><input style="width: 240px;" formControlName="name" [(value)]="currentUser.name"></dd>
            <dt>{{'profile.profile-form.email' | translate}}:</dt>
            <dd><input style="width: 240px;" formControlName="email" [(value)]="currentUser.email" readonly></dd>
            <dt>{{'profile.profile-form.language' | translate}}:</dt>
            <dd #language>
              <select formControlName="language" [(ngModel)]="selectedLanguage" (select)="changeLanguage($event)">
                <option value="en">English</option>
                <option value="uk">Українська</option>
              </select>
            </dd>
          </dl>
          <button class="btn btn-submit" type="submit"
                      [disabled]="requestProcessing" style="width: 120px; margin-top: 20px" (click)="submitProfile(formProfile.value)">
                <i class="fa fa-spinner fa-spin"></i>
            {{'profile.profile-form.save-button' | translate}}
          </button>
        </form>
      </div>
      <div class="row">
        <div class="change-pass-form">
          <form [formGroup]="form" autocomplete="off" >
            <div class="form-group">
              <label for="new-pass">{{'profile.password-form.new-password.label' | translate}}:</label>
              <div class="form-control">
                <input id="new-pass" name="password" [(ngModel)]="entity.password"  formControlName="password"
                       maxlength="100" placeholder="{{'profile.password-form.new-password.placeholder' | translate}}" type="password" autocomplete="off" required>
                <div *ngIf="form.get('password').hasError('required') && $submitted">
                  <p class="validation-error">
                    {{'profile.password-form.new-password.required-error' | translate}}
                  </p>
                </div>
              </div>

            </div>
            <div class="form-group">
              <label for="confirm-pass">{{'profile.password-form.confirm-password.label' | translate}}:</label>
              <div class="form-control">
                <input id="confirm-pass" name="confirmPassword" [(ngModel)]="entity.confirmPassword" formControlName="confirmPassword"
                       maxlength="100" placeholder="{{'profile.password-form.confirm-password.placeholder' | translate}}" type="password" autocomplete="off" required >
                <div *ngIf="$submitted">
                  <p [hidden]="!form.get('confirmPassword').hasError('required')" class="validation-error">
                    {{'profile.password-form.confirm-password.required-error' | translate}}
                  </p>
                  <p [hidden]="!form.get('confirmPassword').hasError('notEquals')" class="validation-error">
                    {{'profile.password-form.confirm-password.match-error' | translate}}
                  </p>
                </div>
              </div>


            </div>
            <div class="btn-group">
              <button class="btn btn-submit" type="submit" (click)="save()"
                      [disabled]="requestProcessing">
                <i class="fa fa-spinner fa-spin" [hidden]="!requestProcessing"></i>
                {{'profile.password-form.save-button' | translate}}
              </button>
              <!--<button class="btn btn-cancel" (click)="cancel()">Cancel</button>-->

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
