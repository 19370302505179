import { OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CurrentUserService } from "./core/current-user.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DropdownDirective } from 'angular-custom-dropdown';
import { AuthService } from "./auth/auth.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(router, activatedRoute, titleService, toastr, vcr, currentUserService, authService, spinnerService, translate) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.titleService = titleService;
        this.toastr = toastr;
        this.currentUserService = currentUserService;
        this.authService = authService;
        this.spinnerService = spinnerService;
        this.translate = translate;
        this.currentUser = {};
        this.visible = true;
        // this.toastr.setRootViewContainerRef(vcr);
        translate.setDefaultLang('en');
        this.router.events.subscribe(function (eventData) {
            // this.onRouteChange(eventData);
            if (eventData['url']) {
                var firstChildren = eventData['url'].split('?')[0];
                _this.visible = (firstChildren === '/login' || firstChildren === '/');
            }
        });
        this.authService.currentUser.subscribe(function (user) {
            _this.currentUser = user.user;
            localStorage.setItem('locale', user.locale);
            _this.translate.use(user.locale);
            _this.loadPars();
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var selectedLanguage = localStorage.getItem('locale');
        if (selectedLanguage) {
            this.translate.use(selectedLanguage);
        }
        if (this.currentUserService.get()) {
            this.loadPars();
        }
    };
    AppComponent.prototype.loadPars = function () {
        this.currentUser = this.currentUserService.get();
    };
    AppComponent.prototype.logout = function () {
        var _this = this;
        this.authService.logout().subscribe(function (result) {
            _this.currentUserService.logout();
        });
    };
    return AppComponent;
}());
export { AppComponent };
