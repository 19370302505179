import { EventEmitter } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
var PaymentService = /** @class */ (function () {
    function PaymentService(http) {
        this.http = http;
        this.onCertificatePurchased = new EventEmitter();
        this.configureStripe();
    }
    PaymentService.prototype.getPayment = function (id) {
        return this.http.get(environment.serverUrl + "/purchases/" + id);
    };
    PaymentService.prototype.configureStripe = function () {
        var _this = this;
        this.handler = StripeCheckout.configure({
            key: 'pk_test_qi7oi12PUlHHlAID7o666Iah',
            image: 'assets/images/doc-logo.png',
            locale: 'auto',
            name: "Student's Vault",
            description: 'Certificate',
            token: function (token) {
                _this.onCertificatePurchased.emit({
                    token: token.id
                });
                console.log(token);
                // You can access the token ID with `token.id`.
                // Get the token ID to your server-side code for use.
            }
        });
    };
    PaymentService.prototype.pay = function (course, priceInCents, criteria, stuentName) {
        this.handler.open({
            email: stuentName,
            name: course,
            description: criteria,
            zipCode: false,
            amount: priceInCents,
            billingAddress: false
        });
    };
    PaymentService.prototype.sendPayment = function (purchaseId, token) {
        return this.http.post(environment.serverUrl + "/purchases/" + purchaseId + "/pay", {
            token: token
        });
    };
    return PaymentService;
}());
export { PaymentService };
