import { ElementRef, EventEmitter, OnChanges } from '@angular/core';
import { Router } from "@angular/router";
var FolderComponent = /** @class */ (function () {
    function FolderComponent(router) {
        this.router = router;
        this.onDelete = new EventEmitter();
        this.onFileMove = new EventEmitter();
        this.onEditName = new EventEmitter();
        this.onExternalClick = new EventEmitter();
        this.isDragged = false;
    }
    FolderComponent.prototype.ngOnChanges = function () {
        if (this.folder && this.folder.isEditing) {
            this.focusNameInput();
        }
    };
    FolderComponent.prototype.focusNameInput = function () {
        var _this = this;
        setTimeout(function () {
            if (!_this.nameInputRef) {
                console.warn('name input ref is not defined');
                return;
            }
            _this.nameInputRef.nativeElement.focus();
        });
    };
    FolderComponent.prototype.editName = function ($event) {
        $event.stopPropagation();
        this.focusNameInput();
        this.onEditName.emit({ id: this.folder.id });
    };
    FolderComponent.prototype.getDropCheck = function () {
        var _this = this;
        return function () { return !_this.isDragged; };
    };
    FolderComponent.prototype.onFolderClick = function (folder) {
        if (folder.isEditing) {
            return;
        }
        if (folder.backFolder && this.tree.length > 1) {
            var parentId = this.tree[this.tree.length - 1].parentId;
            this.router.navigate(['certificates', parentId]);
        }
        else {
            if (folder.id) {
                this.router.navigate(['certificates', folder.id]);
            }
            else {
                this.router.navigate(['certificates']);
            }
        }
    };
    FolderComponent.prototype.fileMove = function (folder, $event) {
        this.onFileMove.emit({ folder: folder, event: $event, });
    };
    FolderComponent.prototype.deleteFolder = function ($event) {
        $event.stopPropagation();
        this.onDelete.emit({ id: this.folder.id });
    };
    FolderComponent.prototype.onKeyPress = function () {
        this.onExternalClick.emit();
    };
    return FolderComponent;
}());
export { FolderComponent };
