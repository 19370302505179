import {Component, OnInit} from '@angular/core';
import {PaymentService} from "./payment.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ICertiificatePurchase, ITemplate} from "../core/app.interfaces";
import { ToastrService } from 'ngx-toastr';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {CurrentUserService} from "../core/current-user.service";

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public entity: ITemplate = {} as ITemplate;
  public thumbnail: string;
  public showSpinner: boolean;


  constructor(private paymentService: PaymentService,
              private route: ActivatedRoute,
              public toastr: ToastrService,
              private router: Router,
              private spinnerService: Ng4LoadingSpinnerService,
              private  currentUserService: CurrentUserService) {

    paymentService.onCertificatePurchased.subscribe((event: ICertiificatePurchase) => this.onCertificatePurchased(event));
  }

  ngOnInit() {
    this.paymentService.getPayment(this.route.snapshot.params['id']).subscribe((item: ITemplate) => {
      this.entity = item;
      this.thumbnail = 'data:image/png;base64,' + this.entity.thumbnail;
      this.spinnerService.hide();
    }, (response) => {
      this.toastr.error(response.error.message, '');
      this.router.navigate(['certificates']);
      this.spinnerService.hide();
    });
  }

  makePay() {
    this.paymentService.pay(this.entity.course, this.entity.priceInCents, this.entity.criteria, this.currentUserService.get().email);
  }

  onCertificatePurchased(event: ICertiificatePurchase) {
    this.spinnerService.show();
    this.paymentService.sendPayment(this.entity.purchaseId, event.token)
      .subscribe((result) => {
          this.toastr.success('Certificate paid successfully', '');
          this.router.navigate(['certificates']);
          this.spinnerService.hide();
        },
        (response) => {
          this.spinnerService.hide();
          this.toastr.error(response.error.message, '');
        });
  }

}
