import {Injectable} from '@angular/core';

import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Folder, ICertificatesFolderContentDto, IItemDto, IPurchase} from "../core/app.interfaces";


@Injectable()
export class CertificatesService {

  constructor(private http: HttpClient) {
  }

  getPurchased(folderId?: string): Observable<ICertificatesFolderContentDto> {
    if (folderId) {
      return this.http.get(`${environment.serverUrl}/students/certificates/${folderId}`) as Observable<ICertificatesFolderContentDto>;
    } else {
      return this.http.get(`${environment.serverUrl}/students/certificates`) as Observable<ICertificatesFolderContentDto>;
    }
  }

  addFolder(name: string, id: string, parentId: string): Observable<Folder> {
    if (id) {
      return this.http.put(`${environment.serverUrl}/folders/update/${id}`, {
        name: name
      }) as Observable<Folder>;
    } else {
      return this.http.post(`${environment.serverUrl}/folders/create`, {
        name: name,
        parentId: parentId
      }) as Observable<Folder>;
    }
  }

  moveFolder(moveFolder: string, parentFolderId: string): Observable<Folder> {
     return this.http.put(`${environment.serverUrl}/folders/update/${moveFolder}`, {
       parentId: parentFolderId
     }
    ) as Observable<Folder>;
  }

  moveToFolder(certificateId: string, folderId: string): Observable<IPurchase> {
    return this.http.post(`${environment.serverUrl}/students/certificates/${certificateId}/move-to-folder`, {
      id: folderId
    }) as Observable<IPurchase>;
  }

  deleteFolder(id: string): Observable<Array<IItemDto>> {
    return this.http.delete(`${environment.serverUrl}/folders/delete/${id}`) as Observable<Array<IItemDto>>;
  }

}
