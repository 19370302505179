import {Injectable} from '@angular/core';

import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IPurchase} from "../core/app.interfaces";


@Injectable()
export class PurchasesService {

  constructor(private http: HttpClient) {
  }

  getPurchases(): Observable<Array<IPurchase>> {
    return this.http.get(`${environment.serverUrl}/purchases`) as Observable<Array<IPurchase>>;
  }

}
