import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { Observable } from 'rxjs/Observable';
import {AuthStateService} from "./auth-state.service";
import {AppEnums} from "./app-constants";
import {Router} from "@angular/router";
import { throwError } from 'rxjs';
// import 'rxjs/add/operator/filter';
// import 'rxjs/add/operator/catch';
// import 'rxjs/observable/throw';
//import 'rxjs/Rx';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _authStateService: AuthStateService,
              private _router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.info("HTTP | ", req.urlWithParams);
    const token = this._authStateService.getToken();

    const handleObs = token
      ? next.handle(req.clone({headers: req.headers.set(AppEnums.storageKeys.XAuthToken, token)}))
        .filter(event => event instanceof HttpResponse)
        .do((response: HttpResponse<any>) => this._refreshToken(response))
      : next.handle(req)
        .filter(event => event instanceof HttpResponse);

    return handleObs.catch(error => this._errorHandler(error));
  }

  private _refreshToken(response: HttpResponse<any>): void {
    //const token = response.headers.get("x-auth-token");
    //if(token) this._authStateService.setToken(token);
  }

  private _errorHandler(error: HttpErrorResponse): Observable<HttpEvent<any>> {

    console.info("HTTP Error | ", error);
    if (error.status == 401) {
      this._authStateService.clearToken();
      this._router.navigate(['login']);
    }
    return throwError(error);
  }

}
