import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Event, Router} from "@angular/router";
import {Folder} from "../../../core/app.interfaces";

@Component({
  selector: 'folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnChanges {

  @ViewChild('nameInput') nameInputRef: ElementRef;

  @Input() folder: any;
  @Input() tree: any;

  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onFileMove: EventEmitter<any> = new EventEmitter();
  @Output() onEditName: EventEmitter<any> = new EventEmitter();
  @Output() onExternalClick: EventEmitter<any> = new EventEmitter();

  public isDragged: boolean = false;

  constructor(private router: Router) {
  }

  ngOnChanges() {
    if (this.folder && this.folder.isEditing) {
      this.focusNameInput();
    }
  }

  focusNameInput() {
    setTimeout(() => {
      if (!this.nameInputRef) {
        console.warn('name input ref is not defined');
        return;
      }
      this.nameInputRef.nativeElement.focus();
    });
  }

  editName( $event: any) {
    $event.stopPropagation();
    this.focusNameInput();
    this.onEditName.emit({id: this.folder.id});
  }

  getDropCheck() {
    return () => !this.isDragged;
  }

  onFolderClick(folder: Folder) {
    if (folder.isEditing) {
      return;
    }

    if (folder.backFolder && this.tree.length > 1) {
      const parentId = this.tree[this.tree.length - 1].parentId;
      this.router.navigate(['certificates', parentId]);
    } else {
      if (folder.id) {
        this.router.navigate(['certificates', folder.id]);
      } else {
        this.router.navigate(['certificates']);
      }
    }
  }

  fileMove(folder: Folder, $event: any) {
    this.onFileMove.emit({folder: folder, event: $event,});
  }

  deleteFolder($event: any) {
    $event.stopPropagation();
    this.onDelete.emit({id: this.folder.id});
  }

  onKeyPress() {
      this.onExternalClick.emit();
  }
}
