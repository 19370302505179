import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {PaymentComponent} from "./payment/payment.component";
import {PaymentService} from "./payment/payment.service";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CertificatesComponent} from "./certificates/certificates.component";
import {PurchasesComponent} from "./purchases/purchases.component";
import {CertificatesService} from "./certificates/certificates.service";
import {PurchasesService} from "./purchases/purchases.service";
import {AuthService} from "./auth/auth.service";
import {LoginPageComponent} from "./auth/login/login.component";
import {CommonModule} from "@angular/common";
import {PasswordPageComponent} from "./auth/password/password.component";
import {AuthInterceptor} from "./core/auth-interceptor";
import {CanActivateApp} from "./app-activate";
import {AuthStateService} from "./core/auth-state.service";
import {CurrentUserService} from "./core/current-user.service";
import {StorageService} from "./core/storage.service";
import {ResetPasswordPageComponent} from "./auth/reset-password/reset-password.component";
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { DropdownModule } from 'angular-custom-dropdown';
import {DndModule} from "ng2-dnd";
import { ToastrModule } from 'ngx-toastr';
import { FolderComponent } from './certificates/items/folder/folder.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ProfileComponent } from './profile/profile.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    //NgbModule.forRoot(),
    DropdownModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    DndModule.forRoot()

  ],
  declarations: [AppComponent, PaymentComponent, CertificatesComponent, PurchasesComponent,
    LoginPageComponent, PasswordPageComponent, ResetPasswordPageComponent, FolderComponent, ProfileComponent],
  providers: [
    StorageService,
    CurrentUserService,
    AuthStateService,

    PaymentService,
    CertificatesService,
    PurchasesService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CanActivateApp
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
