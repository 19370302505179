<main class="middle">
  <div class="content">
    <div class="auto-scroll">
      <div class="row">
        <div class="table-wrap">
          <h3>{{'purchases.title' | translate}}</h3>
          <table>
            <tr>
              <th>{{'purchases.date' | translate}}</th>
              <th>{{'purchases.name' | translate}}</th>
              <th>{{'purchases.email' | translate}}</th>
              <th>{{'purchases.document' | translate}}</th>
              <th>{{'purchases.price' | translate}}</th>
              <th>{{'purchases.msg1-sent' | translate}}</th>
              <th>{{'purchases.msg2-sent' | translate}}</th>
              <th>{{'purchases.paid' | translate}}</th>
              <th>{{'purchases.batch' | translate}}</th>
            </tr>
            <tr *ngFor="let item of purchases">
              <td>{{item.paid | date:'yyyy-MM-dd HH:mm a'}}</td>
              <td>{{item.student.name}}</td>
              <td>{{item.student.eMail}}</td>
              <td>{{item.document}}</td>
              <td>{{item.price}}</td>
              <td>{{item.msg1_sent}}</td>
              <td>{{item.msg2_sent}}</td>
              <td>{{item.paid | date:'yyyy-MM-dd HH:mm a'}}</td>
              <td>{{item.batch.id}}</td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</main>
