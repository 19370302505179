import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Folder, ITemplate} from '../core/app.interfaces';
import {CertificatesService} from './certificates.service';
import {environment} from '../../environments/environment';
import {CurrentUserService} from '../core/current-user.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {AppEnums} from '../core/app-constants';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
  selector: 'certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {

  public certificates: Array<ITemplate> = [];
  public folders: Array<Folder> = [];
  public selectedFolder: Folder;
  public tree: Array<Folder> = [];
  public isDragged: boolean = false;

  constructor(private certificatesService: CertificatesService,
              private route: ActivatedRoute,
              private router: Router,
              private currentUserService: CurrentUserService,
              private spinnerService: Ng4LoadingSpinnerService,
              public toastr: ToastrService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.getPurchasedCertificates(this.route.snapshot.params['folderId']);
    });
  }

  openCertificate(item: ITemplate) {
    window.open(environment.annUrl + '/' + item.document, 'blank');
  }

  buyCertificate(item: ITemplate) {
    return this.router.navigate([item.purchaseId, 'payment']);
  }

  onFileMove(folder: Folder, $event: any) {
    if ($event.event.dragData.id === folder.id)
      return;
    this.spinnerService.show();
    let folderId = folder.backFolder ? this.tree[this.tree.length - 1].parentId : folder.id;

    if ($event.event.dragData.document){ // TODO: add explicit type fields
      this.certificatesService.moveToFolder($event.event.dragData.purchaseId, folderId)
        .subscribe((result) => {
          const index = this.certificates.indexOf($event.event.dragData);
          if (index !== -1) {
            this.certificates.splice(index, 1);
          }
          this.spinnerService.hide();
        }, (response) => {
          this.spinnerService.hide();
          this.toastr.error(response.error.message, '');
        });
    } else if ($event.event.dragData.name) {
      //moveToFolder(what, where)
      this.certificatesService.moveFolder($event.event.dragData.id, folderId)
        .subscribe( (result) => {
          _.remove(this.folders, function(folder) {
            return folder.id === $event.event.dragData.id;
          });
          this.spinnerService.hide();
        }, (error) => {
          this.spinnerService.hide();
          this.toastr.error(error.error.message, '');
        })
    } else {
      this.toastr.error('Unknown object type', '');

    }
  }

  editName(item: Folder, $event: any) {

    this.unfocusRename(item);
    item.isEditing = true;
    this.selectedFolder= item;
  }

  unfocusRename(item: Folder) {
    this.folders.forEach((i) => {
      if (i.id !== item.id) i.isEditing = false;
    });
  }

  addFolder() {
    this.selectedFolder = {
      parentId: (this.tree.length > 0) ? this.tree[this.tree.length - 1].id : null,
      isEditing: true,
      name: ''
    };
    this.folders.push(this.selectedFolder);
  }

  onExternalClick() {
    if (this.selectedFolder) {
      this.selectedFolder.isEditing = false;
      this.save(this.selectedFolder);
    }
  }

  save(folder: Folder) {
    this.selectedFolder = null;
    folder.isEditing = false;
    this.certificatesService.addFolder(folder.name, folder.id, folder.parentId).subscribe((result) => {
      folder.id = result.id;
    }, (response) => {
      this.toastr.error(response.error.message, '');
    });
  }

  onDelete($event:any) {
    this.spinnerService.show();
    this.certificatesService.deleteFolder($event.id).subscribe((result) => {
      _.remove(this.folders, function(folder) {
        return folder.id === $event.id;
      });
      this.spinnerService.hide();
    }, (response) => {
      this.spinnerService.hide();
      this.toastr.error(response.error.message, '');
    });
  }

  getPurchasedCertificates(folderId?: string) {
    this.certificatesService.getPurchased(folderId)
      .subscribe((response) => {
        const items = response.items;
        this.certificates = [];
        this.folders = [];
        this.tree = response.tree.reverse();
        if (folderId) {
          this.folders.push({
            id: response.tree.length > 1 ? response.tree[0].id : null,
            backFolder: true
          });
        }

        items.forEach((item) => {
          if (item.type === AppEnums.fileType.certificate) {
            item.entity.thumbnail = 'data:image/png;base64,' + item.entity.thumbnail;
            this.certificates.push(item.entity);
          } else {
            this.folders.push(item.entity);
          }
        });

        this.spinnerService.hide();
      }, (error) => {
        this.spinnerService.hide();
      });
  }

}
